body {
  margin: 0;
  padding: 0;
  font-family: Raleway;
  background: #eee;
  color: #111;
}
body * {
  transition: all 0.300s ease-in-out;
}

.container { 
  background-color: #fff;
  
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

/*------------- NAVIGATION BAR -------------------*/

.map {
  margin-top: -20px;
  margin-bottom: 5px;
  height:100%;
  width:900px; 
}
.map iframe {
  max-width: 100%;   
  height:450px;
  width:900px; 
  border:0;
}

nav {
  position: fixed;
  z-index: 99;
  background: #fff;
  z-index: 99;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;  
  padding: 12px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

nav ul {
  width: 100%;
  display: grid;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
} 

nav ul:first-of-type{
  grid-template-columns: 1fr;
}
nav ul:first-of-type li{
  font-size: 1.4rem;
  font-weight: bold;
  text-indent: 30px;
}
nav ul:first-of-type li span {
  font-weight: 500;
  color: #444;
}

nav ul:last-of-type{
  grid-template-columns: repeat(6, 1fr);
  text-align: center

}

nav ul li a{
  color:#111;
  text-decoration: none; 
  font-weight: bold;
  display: block;

}

nav ul li span{
  font-size: 15px;
  font-weight: bold;
  color: rgb(2, 2, 2)240, 239, 239);
}

nav ul .active{
  border-bottom: 1px solid black;
}
/*------------- HEADER -------------------*/

header {
  /* background: url("./images/photo-1525184648845-66cbe3b6c59c.jpg");*/
  background: url("./images/movil.jpg");
  background-size: 100%;
  height: 700px;
  background-position: center;
  position: relative;
 
}

header div.head {
  position: relative;
  z-index: 2;
  display: grid;
  width: 70%;
  grid-template-columns: repeat(2,1fr);
  margin: 0 auto;
  padding-top: 12%;
  align-items: center;
  
}

header div.head  {
  color:  #fff; /* 222*/
}

header div.head h1 {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0px;
  margin: 0;
  letter-spacing: 4px;
  border-right: 2px solid #111;
}

header div.head p {
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 2px;
  padding-left: 30px;
}


.contact {
 background: rgb(0, 99, 248);
 display: block;
 color: #fff;
 font-weight: bold;
 width: 50%;
 margin: 0 auto;
 text-align: center;
 padding: 12px;
 border-radius: 10px;
 text-decoration: none; 
 -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}


 /*------------- MAIN SECTION -------------------*/

main {
 
  position: relative;
  z-index: 3;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

main section#galeria {
  width: 70%;
  height: 100%;
  padding: 90px 0;
  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;

}

main section#mapa {
  width: 190px;
  height: 310px;
  margin-top: 190px;
  margin-left: 150px;
  align-items: center;

}
main section#nosotros{
  width: 70%;
  height: 100%;
  padding: 170px 0;
  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
}
main section#contacto {
  width: 80%;
  height: 100%;

  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;

}
main section#inicio h2 {
  font-size: 4.3rem;
  color: #ddd;
  text-transform: uppercase;
 line-height: 4.2rem; 
 padding-right: 10px;
 border-right: 3px solid #ddd;
}

main section#mapa h2 {
  font-size: 4.3rem;
  color: #ddd;
  text-transform: uppercase;
 line-height: 4.2rem; 
 padding-right: 10px;
 border-right: 3px solid #ddd;
}

main h3 {
  font-size: 15px;
  font-weight: bold;
  color: rgb(2, 2, 2)240, 239, 239);
  padding-left: 150px;
  padding-top: 130px;
}

main h4 {
  font-size: 55px;
  font-weight: bold;
  color: rgb(2, 2, 2)240, 239, 239);
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
}

main section#contacto h2 {
  font-size: 4.3rem;
  color: #ddd;
  text-transform: uppercase;
 line-height: 4.2rem; 
 padding-right: 10px;
 border-right: 3px solid #ddd;
}
main section#nosotros h2 {
  font-size: 4.3rem;
  color: rgb(23, 22, 22);
  text-transform: uppercase;
 line-height: 4.2rem; 
 padding-right: 10px;
 border-right: 3px solid #ddd;
}
main section#inicio div {
  position: relative;

}
main section#contacto div {
  position: relative;

}
main section#nosotros div {
  position: relative;

}
main section#mapa div {
  position: relative;

}
main section#inicio div p {
  padding-left: 10px;
}
main section#nosotros div p {
  padding-left: 10px;
}
main section#contacto div p {
  padding-left: 10px;
}
main section#mapa div p {
  padding-left: 10px;
}
/*------------- GALLERY -------------------*/

.gallery{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 180px auto 0px auto;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 -moz-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
   overflow: hidden;
   filter: grayscale(83%);
}

/* images are not compressed, remember to compress them before deployment */
.gallery div.gallery-item-one {
  background: url("./images/versluys.png"); /* toa-heftiba-526264-unsplash.jpg*/
  background-size: 160%;
 grid-column-end: span 2;
 grid-row-end: span 2;
}

.gallery div.gallery-item-two {
  background: url("./images/versluys_2.png"); /* chuttersnap-598266-unsplash.jpg*/
  background-size: 160%;
}
.gallery div.gallery-item-three {
  background: url("./images/dimasoft.png"); /* */
  background-size: 160%;
}

.gallery div.gallery-item-four {
  background: url("./images/versluys_3.png"); /**/
  background-size: 160%;
}
.gallery div.gallery-item-five {
  background: url("./images/advicom.jpg");
  background-size: 160%;
}
.gallery div.gallery-item-six {
  background: url("./images/ibuss.png");
  background-size: 160%;
}
.gallery div.gallery-item-seven {
  background: url("./images/larrondo.png");
  background-size: 160%;
}
.gallery div.gallery-item-eight {
  background: url("./images/mentec.png");
  background-size: 160%;
}
.gallery div.gallery-item-one:hover, .gallery div.gallery-item-two:hover, .gallery div.gallery-item-three:hover, .gallery div.gallery-item-four:hover, .gallery div.gallery-item-five:hover, .gallery div.gallery-item-six:hover, .gallery div.gallery-item-seven:hover,  .gallery div.gallery-item-eight:hover{
  background-size: 100%;
  filter: grayscale(0%);
}

 /*------------- SERVICES -------------------*/

div.services {
  display: grid;
  height: auto;
  grid-template-columns: repeat(3,1fr);
  padding: 110px;

}

.services div {
  text-align: center;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 10px auto 50px auto;
  
  -webkit-box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  
}

.services p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}
.services p.service-title{
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.services p.service-titledet{
  font-size: 18px;
  text-align: center;
}


.services p:last-of-type{
  padding: 0px 0px 0px 0px;  /* padding: 0px 90px 20px 90px; */
  text-align: center;
}


 /*------------- FORM -------------------*/

main section#contacto form {
  padding: 0px;
  margin: 0;
}
main section#contacto h2 {
  font-size: 3rem;
  color:#222;
}

main section#contacto form div {
  border:1px solid #eee;
  padding: 10px;
border-radius: 10px;
font-size: 1.1rem;
display: grid;
margin-bottom: 10px;
grid-template-columns: 1fr 3fr;
align-items: center;

}

main section#contacto form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;

} 

main section#contacto form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section#contacto form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius:10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section#contacto form div input {
  background: none;
  border: none;
  font-size: 1rem;
}
main section#contacto form div textarea {
  font-family: Raleway;
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}
.success {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}


footer {
  background: rgb(0, 99, 248);
  color: #fff;
  padding: 20px;
  margin-top: 80px;
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  align-items: center;
  text-align: center;
 
}

footer ul {
  
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

footer ul li a {
  color: #eee;
}

@media (max-width:1310px) and (min-width:1100px) {
    
   nav ul {
    margin-left: 70px;
    margin-right: 70px;
   }

    header div.head {
      width: 75%;
      padding-top: 190px;
    }
    header div.head h1 {
      font-size: 4rem;
      
    }
    
    main section#inicio {
      width: 90%;
      padding: 90px 0;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;
      
    }
    main section#nosotros {
      width: 90%;
      padding: 90px 0;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;
      
    }
    main section#mapa {
      width: 90%;
      padding: 90px 0;
      padding-top: 200px; 
      display: grid;
      grid-template-columns: 1fr;
      
    }
    main section#galeria {
      width: 90%;
      padding: 90px 0;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;
      
    }
    main section#contacto {
      width: 90%;
      padding: 90px 0;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;
      
    }

    main section#inicio div p {
      padding: 0px 90px;
    }
    main section#mapa div p {
      padding: 100px 90px;
    }
    main section#contacto div p {
      padding: 0px 90px;
    }
    main section#nosotros div p {
      padding: 0px 90px;
    }

    .services p.service-icon {
      font-size: 2.4rem;
      padding: 10px 30px;
      margin: 0px 0px 30px 0px;
      
    }
    .services p.service-title{
      font-size: 2.1rem;
      padding: 0;
      margin: 0;
    }
    
    .services p:last-of-type{
      padding: 0px 20px; 
      text-align: left;
    }

    .gallery{
      height: 600px;
    }

    main h3 {
      font-size: 15px;
      font-weight: bold;
      color: rgb(2, 2, 2)240, 239, 239);
      padding-left: 150px;
      padding-top: 30px;
    }
    
}

@media (max-width:1099px) and (min-width:850px) {

  nav ul {
    margin-left: 70px;
    margin-right: 70px;
  }

  header {
    height: 350px;
    background-position: bottom;
  }

  header div.head {
    width: 75%;
    padding-top: 400px;
    grid-template-columns: 1fr;
    
  }

  header div.head h1 {
    font-size: 2.5rem;
    border: none;
    color: #111;
    padding-top: 30px;
    padding-left: 30px;
  }
  
  header div.head p {
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-left: 10px;
    color: #111;
  }

  main h3 {
  font-size: 15px;
  font-weight: bold;
  color: rgb(2, 2, 2)240, 239, 239);
  padding-left: 55px;
  padding-top: 0px;
  }
  
  main section#inicio:first-of-type{
    margin-top: 400px;
  }
  main section#mapa:first-of-type{
    margin-top: 400px;
  }
  main section#nosotros:first-of-type{
    margin-top: 400px;
  }
  main section#contacto:first-of-type{
    margin-top: 400px;
  }
  main section#inicio {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#mapa {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#galeria {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#contacto {
    width: 90%;
    padding: 0px 0;
    margin: 0px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#nosotros {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#inicio div p {
    padding: 0px 90px;
  }
  main section#contacto div p {
    padding: 0px 90px;
  }
  main section#nosotros div p {
    padding: 0px 90px;
  }
  main section#mapa div p {
    padding: 0px 90px;
  }
  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    height: 420px;
  }
}


@media (max-width:849px) and (min-width:600px) {
  nav ul {
    margin-left: 70px;
    margin-right: 70px;
  }
  header {
    height: 440px;
  }
  
  header div.head {
    width: 75%;
    padding-top: 120px;
    grid-template-columns: 1fr;
    color: #111;
  }

  header div.head h1 {
    font-size: 2.5rem;
    border: none;
    color: #ccc;
    padding-top: 30px;
    padding-left: 30px;
  }
  
  header div.head p {
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-left: 30px;
    color: #ccc;
  }

  main section#inicio {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }

  main section#mapa{
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#galeria {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#contacto {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#nosotros {
    width: 90%;
    padding: 90px 0;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#inicio div p {
    padding: 0px 90px;
  }
  main section#contacto div p {
    padding: 0px 90px;
  }
  main section#mapa div p {
    padding: 0px 90px;
  }
  main section#nosotros div p {
    padding: 0px 90px;
  }
  
  
 div.services {
   display: grid;
   grid-template-columns: 1fr;
  }
   div.services div {
    width: 70%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    height: 400px;
  }
}


@media (max-width:599px) and (min-width:0px) {
  nav {
   text-align: center;
    display: grid;
    grid-template-columns: 1fr;
  }
  
  nav ul {
    margin-bottom: 10px;
    
  }
  nav ul:first-of-type {
    border-bottom:1px solid #111;
    padding-bottom: 10px; 
  }
  
  nav ul:last-of-type li{
   text-align: center;
   width: 100%;
   padding-top: 10px;
   
  }
  
  header {
    background-size: 160%;
    height: 270px;
    position: relative;
   
  }
  
  header div.head {
    width: 75%;
    color: #111;
    grid-template-columns: 1fr;
    padding-top: 260px;
    
  }

  header div.head h1 {
    font-size: 2.3rem;
    border: none;
    color: #111;
    padding-top: 30px;
    padding-left: 30px;
  }
  
  header div.head p {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-left: 30px;
    color: #111;
  }

  main h3 {
    font-size: 15px;
    font-weight: bold;
    color: rgb(2, 2, 2)240, 239, 239);
    padding-left: 30px;
    padding-top: 120px;
  }
  

  main section#contacto h2 {
    font-size: 2.3rem;
    color: #111;
    text-transform: uppercase;
   line-height: 2.6rem; 
   padding-right: 10px;
   margin-top: 0px;
  }

  main section#inicio:first-of-type{
    margin-top: 390px;
  }
  main section#mapa:first-of-type{
    margin-top: 390px;
  }
  main section#galeria:first-of-type{
    margin-top: 390px;
  }
  main section#contacto:first-of-type{
    margin-top: 390px;
  }
  main section#nosotros:first-of-type{
    margin-top: 390px;
  }
  main section#inicio {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#nosotros {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#mapa {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#galeria {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#contacto {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  main section#inicio div p {
    padding: 0px 10px;
  }
  main section#contacto div p {
    padding: 0px 10px;
  }
  main section#nosotros div p {
    padding: 0px 10px;
  }
  main section#mapa div p {
    padding: 0px 10px;
  }
  div.services {
   display: grid;
   grid-template-columns: 1fr;
  }
  div.services div {
    width: 80%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 0px; 
    text-align: center;
  }

  .gallery{
    grid-template-columns: 1fr;
    height: auto;
    margin: 30px auto 0px auto;
  }
  .gallery div {
    height: 200px;
  }

  .gallery div.gallery-item-one {
   grid-column-end: span 1;
   grid-row-end: span 1;
  }

 
  main section#contacto form div label {
    margin-right:10px;
    border-radius: 5px;
  
  }
  
  main section#contacto form div:last-of-type input {
   
    padding: 10px;
    border-radius: 5px;
  
  }
  footer {
    padding: 20px 0;
    text-align: center;
    grid-template-columns:1fr;
  
  }
  footer ul li a {
 font-size: 1.8rem;
  }
  
  
}